/*------------------------------------*\
    FONT FACES
\*------------------------------------*/
@font-face{
    font-family: 'avaza';
    src:url(../fonts/avaza-webfont.eot);
    src:url(../fonts/avaza-webfont.eot?#iefix) format('embedded-opentype'),
    	url(../fonts/avaza-webfont.woff) format('woff'),
    	url(../fonts/avaza-webfont.ttf) format('truetype');
    font-weight:normal;
    font-style:normal;
}
@font-face{
    font-family: 'avaza_mtavruli';
    src:url(../fonts/avazamtavruli-webfont.eot);
    src:url(../fonts/avazamtavruli-webfont.eot?#iefix) format('embedded-opentype'),
    	url(../fonts/avazamtavruli-webfont.woff) format('woff'),
    	url(../fonts/avazamtavruli-webfont.ttf) format('truetype');
    font-weight:normal;
    font-style:normal;
}
/*------------------------------------*\
    FONT FACES END
\*------------------------------------*/

/*------------------------------------*\
    COMMONS
\*------------------------------------*/
* {
    box-sizing: border-box;
}
html {
    background: transparent;
    opacity: 0;
    transition: opacity 0.2s;
}
html.loaded {
    opacity: 1;
}
body {
    min-width: 320px;
    margin: 0 auto;
    background-color: #fff;
    background-repeat: repeat;
    line-height: 1.6;
    font-size: 18px;
    font-family: 'avaza';
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.2s;
    overflow: hidden;
}
body.loaded {
    overflow-y: visible;
    background-color: #121212;
}
.overflow-hidden {
    overflow-y: hidden !important;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: 'avaza_mtavruli';
    line-height: normal;
}
input {
    font-family: 'avaza';
}
img {
    max-width: 100%;
}
a, a:hover {
    color: inherit;
    text-decoration: none;
}

/* uikit defaults rewrite */
.uk-close {
    color: #37474f;
    opacity: 1;
}
.uk-close:focus, .uk-close:hover {
    opacity: 1;
    color: #37474f;
}
.uk-grid {
    margin-left: 0;
}
.uk-grid>* {
    padding-left: 0;
}
.uk-grid+.uk-grid, .uk-grid-margin, .uk-grid>*>.uk-panel+.uk-panel {
    margin-top: 0;
}
.uk-text-danger {
    color: #c11c27!important;
}
.uk-datepicker {
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.80);
    border-radius: 3px;
    padding: 10px 5px 5px;
}
.uk-datepicker span.uk-form-select:after {
    content: '▾';
    font-size: 22px;
    vertical-align: top;
}
.uk-datepicker-table th {
    font-size: 14px;
    color: #fff;
}
.uk-datepicker-table a {
    color: #fff;
    border-radius: 3px;
    width: 20px;
    margin: 0 auto;
}
.uk-datepicker-nav {
    padding: 0 5px;
    margin-bottom: 5px;
}
.uk-datepicker-nav a,
.uk-datepicker-nav a:hover {
    color: #fff;
}
.uk-datepicker-table a.uk-active {
	background: #c11c27;
}
.uk-datepicker-heading .uk-form-select {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    color: #fff;
}
.uk-datepicker-heading .uk-form-select select {
    padding-left: 5px;
}
.uk-datepicker-heading .uk-form-select select option {
    color: #fff;
    background: rgba(30, 30, 30, 0.80);
}
@media (max-width: 1400px) and (min-width: 767px) {
    .uk-datepicker {
        left: auto !important;
        right: 20px;
    }
}
/* uikit defaults rewrite end */

/* custom defined classes */
.no-scroll {
    overflow: hidden;
}
.centerV {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}
.custom-hide {
    display: none;
}
.w-to-black {
    transition: 0.2s;
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.fullheight {
    height: 100%;
}
.blur {
	filter: blur(5px);
}
.main-btn {
    display: inline-block;
    min-width: 265px;
    padding: 20px 25px 18px;
    background: rgba(30, 30, 30, 0.35);
    color: #fff;
    font-size: 14px;
    box-shadow: 0 0 0 3px #fff inset;
    font-family: 'avaza_mtavruli';
    text-align: center;
    transition: 0.2s;
}
.main-btn:hover {
    background-color: #c11c27;
    box-shadow: 0 0 0 0 #fff inset;
}
.main-btn i {
    margin-right: 10px;
    font-size: 17px;
}
.pages-cards {
    max-width: 1320px;
    padding: 20px 20px 25px;
    margin: 0 auto;
}
.pages-cards .uk-grid {
    margin-left: -20px;
    margin-bottom: -20px;
}
.pages-cards .uk-grid + .uk-grid {
    margin-top: 20px;
}
.pages-cards .uk-grid>* {
    padding-left: 20px;
    padding-bottom: 20px;
}
.breadcrumb {
    margin-bottom: -10px;
}
.detail-page .breadcrumb {
    margin-bottom: 5px;
}
.breadcrumb a {
    margin-right: 12px;
    margin-bottom: 10px;
    transition: 0.2s;
}
.breadcrumb a:last-of-type {
    margin-right: 0;
}
.sidebar-heading {
    padding: 8px 0;
    word-wrap: break-word;
    color: #fff;
    font-size: 20px;
    font-family: 'avaza_mtavruli';
    margin-bottom: 15px;
    height: 44px;
}
.block-header-actions .sidebar-heading {
    margin-bottom: 0;
}
.page-sidebar .main-btn.contact {
    width: 100%;
}
@media (max-width: 767px) {
    .pages-cards {
        padding: 20px 15px 25px;
    }
    .pages-cards .uk-grid {
        margin-left: -15px;
        margin-bottom: -15px;
    }
    .pages-cards .uk-grid + .uk-grid {
        margin-top: 15px;
    }
    .pages-cards .uk-grid>* {
        padding-left: 15px;
        padding-bottom: 15px;
    }
}
/* custom defined classes end */

/* scrollbar styles */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: #fff;
}
::-webkit-scrollbar-thumb {
	background: #c11c27;
	border-radius: 0;
}
::-webkit-scrollbar-thumb:window-inactive { background: #c11c27; }
/* scrollbar styles end */

.scroll-to-top, .scroll-to-top:hover {
    display: none;
    position: fixed;
    right: 55px;
    bottom: 90px;
    z-index: 4;
}
.structure-btn {
    padding: 12px 14px;
    background-color: #f2f2f2;
    color: #121212 !important;
    margin: 0 12px;
    display: inline-block;
    box-shadow: 0 0 0 3px #fff inset;
    font-family: 'avaza_mtavruli';
    font-size: 14px;
    min-width: 142px;
    text-align: center;
}
/*------------------------------------*\
    COMMONS END
\*------------------------------------*/

/*------------------------------------*\
    HOME SECTION
\*------------------------------------*/
.wrap-home-fixeds {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.wrap-home-fixeds.scrolled {
    position: relative;
}
.home-seaction {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 16000161;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2), 0 6px 12px rgba(0,0,0,0.27);
}
.video-pattern {
    background-image: url(/public/assets/images/pattern.png);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
}
.background-block {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.background-block-btns {
    margin-bottom: 7%;
    text-align: center;
    z-index: 3;
}
.background-block-btns .main-btn {
    margin: 10px;
}
@media (max-width: 500px) {
    .background-block-btns {
        margin-bottom: 20%;
    }
}
/* HEADER */
.header {
    position: relative;
    z-index: 16000164;
}
.header-wrap {
    position: relative;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15);
    z-index: 1;
	background-color: #c11c27;
	display: flex;
    align-items: center;
    min-height: 52px;
}
.header-grid {
    display: flex;
    width: 100%;
}
.header-grid-item {
    display: flex;
    align-items: center;
}
.header-grid-item:first-of-type {
    width: 40%;
}
.header-grid-item:last-of-type {
    width: 60%;
    justify-content: flex-end;
}
.header-logo {
    padding: 10px 15px;
    position: relative;
    padding-left: 90px;
    margin: 0;
}
.header-logo img {
    margin: 0 auto;
    position: absolute;
    top: 10px;
    left: 15px;
}
.header-logo figcaption {
	margin: 3px 0 0 10px;
	font-size: 20px;
	line-height: 1.2;
	font-family: 'avaza_mtavruli';
}
.header-nav-box {
	position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: box-shadow 0.3s;
}
.header.sticky .header-nav-box {
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 0 10px rgba(0,0,0,.15), 0 3px 3px rgba(0,0,0,.15);
}
.header-nav-box:before {
    display: block;
    background: linear-gradient(0deg,transparent 0,#020202 75%);
    content: "";
    position: absolute;
    top: 0;
    bottom: -1.25em;
    left: 0;
    right: 0;
    opacity: .75;
}
.header.sticky .header-nav-box:before {
    display: none;
}
.header-nav-box a {
	display: block;
	margin: 14px 10px 12px;
	font-size: 14px;
	font-family: 'avaza_mtavruli';
	color: #fff;
    text-align: center;
    position: relative;
    transition: color .3s ease-in,background-color .3s ease-in;
}
.header.sticky .header-nav-box a {
    color: #121212;
}
.header.sticky .header-nav-box a:hover {
    color: #c11c27;
}
.header-nav-box a:after {
    display: block;
    content: "";
    transition: 0.3s;
    background-color: #fff;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
}
.header-nav-box a:hover:after {
	width: 100%;
}
.header.sticky .header-nav-box a:after {
    background-color: #c11c27;
}
.header-pwd-box {
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	padding: 5px 15px;
}
.header-font-resizer {
	display: flex;
    justify-content: center;
    align-items: center;
}
.header-font-resizer i {
	font-size: 10px;
	cursor: pointer;
}
.header-font-resizer i:nth-child(2) {
	font-size: 15px;
    margin: 0 3px;
}
.header-lang-box {
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	padding: 5px 15px;
    font-family: avaza_mtavruli;
}
.header-lang-box.coupon {
    background: #ffb300;
    color: #000000;
    line-height: 40px;
    height: 40px;
    font-weight: bold;
    padding: 0 20px;
    flex-direction: row;
    transition: 0.3s;
}
.header-lang-box.coupon img {
    margin-right: 8px;
}
.header-lang-box.coupon:hover {
    color: #c11c27;
}
.header-lang-box a {
    border-bottom: 1px solid transparent;
    transition: 0.2s;
    position: relative;
}
.header-lang-box a:after {
    display: block;
    content: "";
    transition: 0.3s;
    background-color: #fff;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
}
.header-lang-box a:hover:after {
    width: 100%;
}
.header-lang-box a {
	display: block;
	margin: 5px 0;
}
@media (max-width: 1160px) {
    .header-lang-box {
        font-size: 12px;
    }
    .header-lang-box.coupon img {
        width: 20px;
    }
}
.header-search-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
}
.search-transform {
    display: flex;
    flex-direction: column;
    transform: translateY(200px);
    transition: 0.5s;
    height: 100%;
}
.search-transform.active {
    transform: translateY(0);
}
.search-btn {
    background: transparent;
    border: none;
    outline: none;
    position: relative;
	cursor: pointer;
    z-index: 10;
}
.search-btn i {
    color: #fff;
    font-size: 26px;
}
.m-logo {
	display: none;
}
.mobile-offcanvas-bar {
    background-color: #c11c27;
    padding: 20px 0;
}
.mobile-menu-logo {
	margin-bottom: 15px;
}
.mobile-nav a {
    display: block;
    padding: 9px 15px 5px;
    font-family: avaza_mtavruli;
}
.mobile-menu-actions .header-pwd-box {
    display: flex;
    border-right: none;
    padding: 15px 15px;
    margin-top: 15px;
}
.header-color-changer {
    width: 21px;
}
.header-color-changer img {
	cursor: pointer;
    margin-top: -2px;
}
.mobile-menu-actions .header-lang-box {
    display: block;
    border-right: none;
    text-align: center;
    padding: 15px 15px;
}
.mobile-menu-actions .header-lang-box a {
    display: inline-block;
    margin: 0 10px;
}
.mobile-menu-actions .header-font-resizer {
	margin-bottom: 20px;
}
.mobile-menu-actions .header-font-resizer i {
	font-size: 20px;
}
.mobile-menu-actions .header-font-resizer i:nth-child(2) {
    margin: 0 10px;
	font-size: 25px;
}
.search-res-box {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 320px;
    z-index: 16000163;
}
.search-res-box.active {
    display: block;
}
.search-blur {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
}
.search-input-box {
    padding: 45px 30px 30px;
}
.search-field-box {
    display: flex;
    justify-content: center;
}
.search-field {
    background: #fff;
    width: 350px;
    padding: 20px 25px;
    font-size: 14px;
    border: 1px solid #4bacff;
    outline: none;
    color: #121212;
}
.search-field-btn {
    background: #c11c27;
    color: #fff;
    outline: none;
    border: none;
    padding: 0 50px;
    cursor: pointer;
    font-family: 'avaza_mtavruli';
    font-size: 14px;
}
.searched-modules-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.searched-module-text {
    margin-right: 15px;
    font-family: 'avaza_mtavruli';
    color: #fff;
}
.searched-module {
    margin: 0 15px;
    font-family: 'avaza_mtavruli';
    cursor: pointer;
    color: #fffefe;
    opacity: 0.7;
}
.searched-module.active {
    color: #fff;
    opacity: 1;
}
.searched-module-title {
    position: relative;
}
.searched-module-title:after {
    display: block;
    content: "";
    transition: 0.3s;
    background-color: #c11c27;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
}
.searched-module-title:hover:after, .searched-module.active .searched-module-title:after {
    width: 100%;
}
.searched-module-count {
    font-size: 12px;
    color: #fffefe;
}
.searched-module.active .searched-module-count {
    color: #fff;
}
.search-results {
    display: none;
    overflow-y: auto;
}
.search-results::-webkit-scrollbar-track {
    background: transparent;
}
.search-results::-webkit-scrollbar-thumb {
    background: #fff;
}
.search-results::-webkit-scrollbar-thumb:window-inactive { background: #fff; }
.search-results-item {
    padding: 30px;
    border-bottom: 1px solid #d0d0d0;
    font-size: 22px;
    transition: 0.2s;
}
.search-results-item:last-child {
    border-bottom: none;
}
.search-results-item.hover {
    background: #c11c27;
}
.search-higlight {
    border-bottom: 1px dotted #fff;
}
@media (max-width: 1700px) {
    .search-transform {
        transform: translateY(100px);
    }
}
@media (max-width: 1280px) {
	.header-logo figcaption {
	    font-size: 16px;
	}
	.header-pwd-box,
	.header-lang-box,
	.header-search-box {
		padding: 5px 15px;
	}
    .search-transform {
        transform: translateY(100px);
    }
}
@media (max-width: 1140px) {
	.header-logo figcaption {
	    margin-top: 4px;
	    font-size: 15px;
	}
    .search-transform {
        transform: translateY(60px);
    }
}
@media (max-width: 1000px) {
	.header-logo,
	.header-pwd-box,
	.header-lang-box,
	.header-nav-box {
		display: none;
	}
	.header-grid {
	    padding: 10px 0;
	}
	.header-grid-item:first-of-type,
	.header-grid-item:last-of-type,
	.header-grid-item {
	    width: 15%;
	}
	.header-grid-item:first-of-type {
		justify-content: flex-start;
	}
	.header-grid-item:last-of-type {
		justify-content: flex-end;
	}
	.m-logo,
	.m-menu-icon {
		display: flex;
	    align-items: center;
	}
	.m-logo {
		justify-content: center;
        width: 70%;
        flex-direction: column;
	}
    .m-logo .header-lang-box.coupon {
        display: flex;
        margin: 10px 10px 0;
    }
	.m-menu-icon {
		padding: 5px 30px;
	    font-size: 26px;
	    cursor: pointer;
	}
	.header-search-box {
		width: auto;
	    padding: 5px 30px;
	}
    .search-transform {
        transform: translateY(40px);
    }
}
@media (max-width: 767px) {
	.m-logo img {
		max-height: 50px;
	}
	.m-menu-icon,
	.header-search-box {
	    padding: 5px 15px;
	}
    .search-transform {
        transform: translateY(15%);
    }
    .search-input-box {
        padding: 30px 10px 10px;
    }
    .searched-module-count {
    	padding-top: 3px;
    }
    .searched-module-text {
        margin-right: 10px;
    }
    .searched-module {
        margin: 0 5px;
        font-size: 14px;
    }
    .search-field {
        padding: 15px 10px;
    }
    .search-field-btn {
        padding: 0 30px;
    }
    .search-results-item {
        padding: 15px;
        font-size: 16px;
    }
}
@media (max-width: 420px) {
    .m-logo .header-lang-box.coupon {
        padding: 0 10px;
    }
    .header-lang-box.coupon img {
        width: 15px;
    }
    .m-logo .header-lang-box.coupon {
        font-size: 11px;
    }
}
/*------------------------------------*\
    HOME SECTION END
\*------------------------------------*/

/*------------------------------------*\
    SIDE WIDGET
\*------------------------------------*/
.widget-icons {
    position: absolute;
    right: 0;
    color: #fff;
    z-index: 16000162;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
}
.widget-icons > a {
    display: flex;
    height: 50px;
    font-size: 18px;
}
.widget-title {
    height: 50px;
    padding: 3px 10px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'avaza_mtavruli';
    position: relative;
    right: -100%;
    background-color: #c11c27;
    transition: 0.2s;
    pointer-events: auto;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12),0px 6px 8px 0px rgba(0, 0, 0, 0.27);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.widget-text-only {
    font-family: 'avaza_mtavruli';
    font-size: 14px;
}
.widget-title.animate {
	right: -100% !important;
}
.widget-icon {
    width: 48px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    background: rgba(61, 61, 61, 0.5);
    border-bottom: 1px solid #ababab;
}
.widget-icon.borderno {
	border-bottom-color: transparent;
}
.widget-icons a:last-of-type .widget-icon {
    border-bottom: none;
}
.widget-icons a:hover .widget-icon {
    background-color: #c11c27;
    border-bottom-color: transparent;
}
.widget-icons .quiz {
    margin-bottom: 10px;
}
.widget-icons .quiz .widget-icon,
.widget-icons .quiz:hover .widget-icon,
.widget-icons .quiz .widget-title {
    background: #ffb300;
    color: #212121;
    font-weight: bold;
    border-bottom: none;
}
@media (max-width: 767px) {
    .widget-icons .quiz {
        margin-bottom: 0;
    }
    .widget-icons {
        position: fixed;
        left: 0;
        top: auto;
        bottom: 0;
        right: auto;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        transform: translateY(100%);
        transition: 0.2s;
        z-index: 3;
    }
    .widget-icons > a {
        width: 100%;
    }
    .widget-icons.animate {
        -webkit-transform: translateY(0) !important;
        -moz-transform: translateY(0) !important;
        transform: translateY(0) !important;
    }
    .widget-title {
        display: none;
    }
    .widget-icon {
        border-bottom: none;
        width: 100%;
    }
}
/*------------------------------------*\
    SIDE WIDGET END
\*------------------------------------*/

/*------------------------------------*\
    ANNOUNCES WIDGET
\*------------------------------------*/
.announces-icons {
    position: absolute;
    left: 0;
    color: #fff;
    z-index: 4;
    display: flex;
    flex-direction: column;
    font-family: 'avaza_mtavruli';
    font-size: 16px;
    font-weight: bold;
}
.announces-item {
	display: flex;
	padding: 12px 15px 10px;
	text-align: center;
	justify-content: center;
}
.announces-title {
  	background: rgba(43, 43, 43, 0.75);
}
.announces-more {
	background: #fff;
	color: #3d3d3d;
}
.announces-day {
	position: relative;
}
.announces-date {
	flex-direction: column;
	background: rgba(61, 61, 61, 0.5);
	cursor: pointer;
	transition: 0.2s;
	box-shadow: 0 1px 0 0 #d0cdcc;
}
.announces-date:last-of-type {
	box-shadow: none;
}
.announces-day:hover .announces-date {
	background: rgba(255, 255, 255, 0.8);
	color: #3d3d3d;
}
.announces-day-data {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease;
    text-align: left;
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 450px;
    background: rgba(255, 255, 255, 0.8);
    transform: rotateX(45deg);
}
.announces-day:hover .announces-day-data {
	visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: rotateX(0);
}
.announces-day-data a {
    display: block;
    margin: 12px 15px 10px;
    padding-bottom: 10px;
    color: #3d3d3d;
    border-bottom: 1px solid #a9a9a9;
}
.announces-day-data a:last-child {
	border-bottom: none;
	padding-bottom: 0;
}
.announces-day-data a:hover {
	color: #c11c27;
}
/*------------------------------------*\
    SIDE WIDGET END
\*------------------------------------*/

/*------------------------------------*\
    FIXED QUIZ
\*------------------------------------*/
.fixed-quiz-box {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 4;
    pointer-events: none;
}
.fixed-quiz-box.show {
    pointer-events: auto;
}
.fixed-quiz-toggler {
    background-color: #ffb300;
    width: 48px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    color: #212121;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    pointer-events: auto;
}
.fixed-quiz-toggler i {
	font-size: 20px;
}
.fixed-quiz-question-box {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 48px;
    transform: scale(0.9);
    background-color: #fff;
    width: 290px;
    margin-right: 15px;
    box-shadow: 0 6px 8px rgba(0,0,0,0.21), 0 12px 14px rgba(0,0,0,0.32);
    transition: 0.2s;
}
.fixed-quiz-question-box.show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}
.fixed-quiz-question-title {
    background-color: #ffb400;
    height: 50px;
    padding: 2px 15px 0;
    font-size: 16px;
    color: #000;
    display: flex;
    font-weight: bold;
    letter-spacing: 1px;
    align-items: center;
    text-transform: uppercase;
}
.fixed-quiz-answer-wrap {
    display: inline-block;
    margin: 10px 0;
    padding: 0 15px;
    cursor: pointer;
    color: #212121;
    font-size: 16px;
}
.fixed-quiz-answer-btn {
    margin-right: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #8f8f8f;
    width: 13px;
    height: 13px;
    outline: none;
    cursor: pointer;
    position: relative;
    top: 1px;
    transition: 0.2s;
}
.fixed-quiz-answers input[type=radio]:checked, .fixed-quiz-answer-wrap:hover input[type=radio] {
    background: #ee524b;
    border-color: #ee524b;
}
.fixed-quiz-vote-counts {
    padding: 0 15px;
}
.fixed-quiz-vote-percent-box {
    padding: 10px 0;
    border-bottom: 1px solid #dfdfdf;
}
.fixed-quiz-vote-percent-box:last-of-type {
    border-bottom: none;
}
.fixed-quiz-vote-title {
    color: #7e7e7e;
    font-size: 16px;
    margin-bottom: 5px;
}
.fixed-quiz-vote-percent-box.chosen .fixed-quiz-vote-title {
    color: #3d3d3d;
}
.fixed-quiz-vote-percent-box .uk-progress {
    height: 26px;
    margin-bottom: 15px;
    line-height: 26px;
    padding-right: 55px;
    background: transparent;
    overflow: visible;
}
.fixed-quiz-vote-percent-box .uk-progress:last-of-type {
    margin-bottom: 0;
}
.fixed-quiz-vote-percent-box .uk-progress-bar {
    background: #e6e6e6;
    position: relative;
}
.fixed-quiz-vote-percent-box.chosen  .uk-progress-bar {
    background: #c11c27;
}
.fixed-quiz-vote-percent {
    position: absolute;
    top: 2px;
    right: -5px;
    transform: translateX(100%);
    font-size: 16px;
    color: #7e7e7e;
}
.fixed-quiz-vote-percent-box.chosen .fixed-quiz-vote-percent {
    color: #c11c27;
}
@media (max-width: 767px) {
	.fixed-quiz-box {
		position: fixed;
		left: 0;
    	right: 0;
	    z-index: 11;
        width: 290px;
        margin: 0 auto;
	}
	.fixed-quiz-question-box {
		position: initial;
		right: auto;
		margin: 0 auto;
	}
    .mobile-widget-dim {
        display: block;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(61, 61, 61, 0.7);
        transition: 0.2s;
    }
    .mobile-widget-dim.show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    .fixed-quiz-toggler {
        width: 100%;
    }
}
/*------------------------------------*\
    FIXED QUIZ END
\*------------------------------------*/

/*------------------------------------*\
    SUBSCRIBE FORM
\*------------------------------------*/
.uk-modal {
    background: rgba(61, 61, 61, 0.7);
    z-index: 16000165;
}
.subscribe-form-wrap .uk-modal-dialog {
    background: transparent;
    max-width: 440px;
}
.subscribe-form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 6px 8px rgba(0,0,0,0.21), 0 12px 14px rgba(0,0,0,0.32);
}
.subscribe-form-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    background: #fff;
    color: #3d3d3d;
    display: flex;
    padding-bottom: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
.subscribe-form-title {
    padding: 10px 30px 5px;
    background-color: #dfdfdf;
}
.subscribe-form-fields-wrap {
    padding: 15px 30px;
}
.subscribe-form-email-field {
    width: 100%;
    background: #fafafa;
    border: 1px solid #dfdfdf;
    outline: none;
    padding: 10px 15px 7px;
    font-family: 'avaza_mtavruli';
    margin-bottom: 10px;
}
.subscribe-form-email-field[placeholder] {
    text-overflow: ellipsis;
}
.subscribe-form-checkbox-wrap {
    border-bottom: 1px solid #d1d1d1;
}
.subscribe-form-checkbox-wrap:last-of-type {
    border-bottom: none;
}
.subscribe-form-checkbox {
    display: inline-block;
    margin: 10px 0;
    padding: 0;
    cursor: pointer;
    text-transform: capitalize;
    color: #3d3d3d;
}
.subscribe-form-checkbox-wrap input {
    margin: 0 8px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    border: 2px solid #7e7e7e;
    width: 13px;
    height: 13px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    transition: 0.2s;
}
.subscribe-form-checkbox input[type=checkbox]:checked,
.subscribe-form-checkbox:hover input[type=checkbox] {
    background: #c11c27;
    border-color: #c11c27;
}
.subscribe-form-submit {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #c11c27;
    outline: none;
    border: none;
    font-family: 'avaza_mtavruli';
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
.subscribe-form-submit i {
    margin-right: 10px;
}
@media (max-width: 767px) {
    .subscribe-form, .subscribe-form-wrap .uk-modal-dialog {
        max-width: 320px;
    }
}
/*------------------------------------*\
    SUBSCRIBE FORM END
\*------------------------------------*/

/*------------------------------------*\
    BLOCK STYLES
\*------------------------------------*/
.home-block {
    padding-bottom: 95px;
}
.home-block.news {
    padding-top: 95px;
}
.home-block .uk-grid {
    margin-left: -25px;
    margin-bottom: -25px;
}
.home-block .uk-grid>* {
    padding-left: 25px;
    padding-bottom: 25px;
}
.home-block-title {
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
}
.block-header-title {
    padding-top: 3px;
    color: #3d3d3d;
    font-size: 20px;
}
.block-header-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.block-header-btn {
    margin-left: 15px;
    padding: 10px 14px 9px;
    width: 46px;
    background-color: #d9d9d9;
    text-transform: uppercase;
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
}
.block-header-btn.subscribe {
    background-color: #c11c27;
    color: #fff;
    text-transform: uppercase;
}
.block-header-btn.subscribe i {
    margin-right: 5px;
    position: relative;
    top: -1px;
}
.block-header-btn.changer {
    position: relative;
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.35);
}
.block-header-btn.changer:hover {
    background-color: #c11c27;
    box-shadow: 0 0 0 0 #fff inset;
}
.background-block-btns.more-btn {
    margin-bottom: 30px;
}
.background-block-btns.more-btn a {
    min-width: 150px;
}
.portal-block {
    margin: 0 auto;
    max-width: 1030px;
    padding-right: 15px;
    padding-left: 15px;
}
.portal-block .home-block-title {
    margin-bottom: 20px;
    font-size: 32px;
}
.portal-slider-content-grid {
    display: flex;
}
.portal-slider-box,
.portal-slider-content-grid > div {
    position: relative;
}
.portal-slider-content-grid > div:first-of-type {
    width: 65%;
}
.portal-slider-content-grid > div:last-of-type {
    width: 35%;
}
.portal-slider-img-box {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 400px;
    height: 100%;
}
.portal-slider-play {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
.portal-slide-data-box {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 15px;
}
.portal-slider-title {
    color: #857c7c;
    height: 70px;
    margin-bottom: 0;
}
.portal-slide-data {
	display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 70px);
}
.portal-slide-data.quote {
	height: 100%;
}
.portal-slide-title {
    font-size: 24px;
    color: #c11c27;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'avaza';
}
.portal-slide-descr {
    color: #212121;
    margin-bottom: 15px;
    max-height: 115px;
    overflow: hidden;
}
.portal-slide-more,
.portal-slide-more:hover {
    color: #c11c27;
}
.portal-nav {
    position: absolute;
    left: 15px;
    border: 3px solid #fff;
    width: 52px;
    height: 52px;
    background: rgba(33, 33, 33, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    cursor: pointer;
    transition: background 0.2s;
}
.portal-nav:hover {
    background: rgba(33, 33, 33, 0.5);
}
.portal-nav.left {
    display: none;
}
.portal-nav.right {
    left: auto;
    right: calc(35% + 15px);
}
.choose-future-logo {
    margin: 0 auto 50px;
    max-width: 1030px;
    padding: 0 15px;
}
.choose-future-logo img {
    max-width: 530px;
    width: 100%;
}
.portal-slider-quote {
    font-size: 40px;
    color: #c11c27;
    display: inline-block;
    margin-bottom: 20px;
}
.portal-slide-name {
    margin-bottom: 15px;
    color: #212121;
    font-size: 18px;
    font-weight: bold;
}
.portal-slide-prof {
    margin-bottom: 15px;
    color: #857c7c;
}
.portal2 .portal-slider-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px 85px;
    height: 100%;
}
.portal2 .portal-slider-img-box a {
    display: inline-block;
    margin: 10px;
    width: 28%;
    transition: 0.2s;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.portal2 .portal-slider-img-box a:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}
.portal2 .portal-nav {
    color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
    background: rgba(33, 33, 33, 0.05);
}
.portal2 .portal-nav:hover {
    background: rgba(33, 33, 33, 0.1);
}
@media (max-width: 1370px) {
    .home-block .uk-grid {
        margin-left: -10px;
        margin-bottom: -20px;
    }
    .home-block .uk-grid>* {
        padding-left: 10px;
        padding-bottom: 20px;
    }
}
@media (max-width: 1200px) {
    .home-block {
        padding-bottom: 60px;
    }
    .home-block.news {
        padding-top: 55px;
    }
    .home-block-title {
        font-size: 40px;
    }
}
@media (max-width: 767px) {
    .home-block {
        padding-bottom: 50px;
    }
    .home-block.news {
        padding-top: 45px;
    }
    .home-block-title {
        font-size: 35px;
    }
    .portal-block .home-block-title {
        font-size: 22px;
    }
    .portal-slider-content-grid {
        display: block;
        height: auto;
    }
    .portal-slider-content-grid > div:first-of-type,
    .portal-slider-content-grid > div:last-of-type {
        width: 100%;
    }
    .portal-slider-img-box {
        min-height: 300px;
    }
    .portal2 .portal-slider-img-box {
        height: auto;
        padding: 10px 65px;
    }
    .portal-slider-title {
        margin-bottom: 20px;
    }
    .portal-slide-descr {
        height: auto;
    }
    .portal-nav {
        top: 60px;
    }
    .portal-nav.right {
        right: 15px;
    }
    .choose-future-logo {
        margin-bottom: 30px;
        text-align: center;
    }
    .portal-slider-quote {
        margin-top: 20px;
    }
    .portal2 .portal-slider-img-box a {
        width: 40%;
    }
}
@media (max-width: 479px) {
    .portal-slider-img-box {
        min-height: 200px;
    }
    .portal2 .portal-slider-img-box a {
        width: 32%;
    }
}
/*------------------------------------*\
    BLOCK STYLES END
\*------------------------------------*/

/*------------------------------------*\
    HOME PAGE NEWS BLOCK
\*------------------------------------*/
.news-block {
    padding: 0 15px;
}
.news-carousel .slick-slide {
    outline: none;
}
.news-carousel-item {
    padding: 0 10px;
    max-width: 370px;
    display: inline-block;
}
.news-nav {
    position: absolute;
    top: 45%;
    z-index: 1;
    border: 3px solid #fff;
    width: 52px;
    height: 52px;
    background: rgba(33, 33, 33, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    cursor: pointer;
    transition: background 0.2s;
}
.news-nav:hover {
    background: rgba(33, 33, 33, 0.5);
}
.news-nav.left {
    left: 0;
}
.news-nav.right {
    right: 0;
}
.news-nav.slick-disabled {
    display: none !important;
}
.news-card {
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
    transition: 0.2s;
}
.news-card.hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.16), 0 8px 16px rgba(0,0,0,0.16);
}
.news-card-img-wrap {
    position: relative;
    overflow: hidden;
}
.news-card-img {
    display: block;
    width: 100%;
    height: 330px;
    object-fit: cover;
    transition: transform .3s ease-in;
}
.news-card.hover .news-card-img {
    transform: scale(1.04);
    transition: transform .3s ease-out;
}
.news-card-pin {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 10px;
    background-color: #f1ba04;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
}
.news-card-info-wrap {
    position: relative;
    padding: 15px;
}
.news-card-date {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #c11c27;
    margin-top: -49px;
    margin-bottom: 15px;
    color: #fff;
    font-family: 'avaza_mtavruli';
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
}
.news-card-title {
    padding-top: 3px;
    transition: 0.2s;
    word-wrap: break-word;
    height: 70px;
    overflow: hidden;
    color: #121212;    
}
.news-card.hover .news-card-title {
    color: #c11c27;
}
.news-card-tags-wrap {
    overflow: hidden;
    height: 21px;
    margin-top: 8px;
}
.news-card-tags {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 14px;
    color: #121212;
    text-transform: capitalize;
    font-family: 'avaza_mtavruli';
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.news-card-tags:hover {
    color: #121212;
}
.page-news .news-card-tags {
    width: auto;
    margin-right: 10px;
}
.page-news .news-card-tags-wrap {
    height: auto;
}
.news-block .background-block-btns {
    margin-bottom: 0;
    margin-top: 70px;
}
.news-block .main-btn {
    min-width: 235px;
}
.news-block .main-btn.subscribe {
    box-shadow: none;
    background-color: #c11c27;
    color: #fff;
}
.news-block .main-btn.subscribe:hover {
    background: #fff;
    color: #c11c27;
}
@media (max-width: 1640px) {
    .news-card-img {
        height: 270px;
    }
}
@media (max-width: 1370px) {
    .news-card-img {
        height: 250px;
    }
}
@media (max-width: 767px) {
    .news-block .background-block-btns {
        margin-top: 30px;
    }
}
/*------------------------------------*\
    HOME PAGE NEWS BLOCK END
\*------------------------------------*/

/*------------------------------------*\
    PARTNERS
\*------------------------------------*/
.partners-carousel {
    position: relative;
    padding: 10px 40px;
    overflow: hidden;
}
.partners-carousel .slick-track {
    height: 80px;
}
.partners-carousel .slick-slide .partners-carousel-item a {
    display: block;
    position: relative;
    transition: 0.2s;
}
.partners-carousel .slick-slide .partners-carousel-item a.animate {
    top: 0;
    opacity: 1;
}
.partners-carousel .slick-slide, .partners-carousel .slick-slide a {
    outline: none;
}
.partners-carousel .slick-slide > div {
    height: 100%;
}
.partners-carousel-item {
    padding: 0 10px;
}
.partners-carousel-item img {
    max-height: 90px;
    margin: 0 auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: .4s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.partners-carousel-item:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    transition: .4s;
}
.partners-nav {
    position: absolute;
    top: 0;
    height: 100%;
    padding: 0 20px;
    font-size: 25px;
    color: #7e7e80;
    transition: 0.2s;
    cursor: pointer;
    z-index: 1;
}
.partners-nav:hover {
    color: #58585a;
}
.partners-nav.left {
    left: 0;
}
.partners-nav.right {
    right: 0;
}
.contact-page-map {
    height: 350px;
}
@media (max-width: 1170px) {
    .partners-nav {
        padding: 0 10px;
    }
    .contact-page-map {
        height: 250px;
    }
}
/*------------------------------------*\
    PARTNERS END
\*------------------------------------*/

/*------------------------------------*\
    PAGE
\*------------------------------------*/
.page-wrap .uk-grid {
    margin-left: -12px;
    margin-bottom: -12px;
}
.page-wrap .uk-grid>* {
    padding-left: 12px;
    padding-bottom: 12px;
}
.page-wrap .uk-grid + .uk-grid {
    margin-top: 15px !important;
}
.page-backgr-wrap {
    background-image: url(/public/assets/images/bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.page-wrap {
    max-width: 1020px;
    padding: 20px 10px 25px;
    margin: 0 auto;
}
.page-wrap.medium {
    max-width: 1320px;
}
.page-title {
    display: inline-block;
    padding: 8px 14px;
    background-color: #ffb300;
    text-align: center;
    color: #121212;
    font-size: 20px;
    font-family: 'avaza_mtavruli';
    word-wrap: break-word;
    transition: 0.2s;
}
.page-title:hover {
    background-color: #fff;
    color: #121212;
}
.page-title.full {
    width: 100%;
}
.page-headbar {
    display: flex;
    justify-content: space-between;
}
.page-headbar-btns {
    min-width: 275px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 10px;
}
.page-headbar-btns.contact {
    min-width: auto !important;
    margin-top: 0;
    justify-content: flex-end;
}
.page-headbar-btns .main-btn {
    min-width: auto;
    padding: 13px 15px 12px;
}
.page-headbar-btns .main-btn:first-of-type {
    margin-right: 10px
}
.article-wrap {
    padding: 25px;
    background-color: #fff;
    color: #121212;
    font-size: 15px;
    word-break: break-word;
}
.article-wrap a {
    color: #fc514e;
}
.side-menu {
    margin-bottom: 20px;
}
.side-menu a {
    display: block;
    padding: 10px 10px 9px;
    border-bottom: 1px solid #bb8300;
    background-color: #ffb300;
    text-align: center;
    color: #121212;
    font-size: 14px;
    font-family: 'avaza_mtavruli';
    transition: 0.2s;
}
.side-menu a.active, .side-menu a:hover {
    background-color: #fff;
}
.page-contact-info {
    margin-bottom: 20px;
    background-color: #fff;
}
.page-contact-info h4 {
    background-color: #f2f2f2;
    padding: 15px 10px;
    text-align: center;
    color: #121212;
    font-size: 20px;
    font-family: 'avaza_mtavruli';
}
.page-contact-info a {
    display: block;
    padding: 15px 10px;
    margin: 0 10px;
    border-bottom: 1px solid #d7d7d7;
    transition: 0.2s;
    text-align: center;
    color: #121212;
    font-size: 12px;
    font-family: 'avaza';
    word-wrap: break-word;
}
.page-contact-info a:hover {
    color: #fc514e;
}
.main-btn.contact {
    min-width: auto;
    margin-right: 0 !important;
}
.contact-form-modal-wrap .uk-modal-dialog {
    max-width: 660px;
    background: transparent;
}
.contact-modal-form-wrap {
    position: relative;
}
.contact-modal-form-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #555555;
    cursor: pointer;
}
.contact-modal-form-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: #121212;
    text-align: center;
    text-transform: uppercase;
}
.contact-modal-form-wrap {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
}
.contact-modal-form .uk-grid {
    margin-left: -10px;
    margin-bottom: -15px;
}
.contact-modal-form .uk-grid > * {
    padding-left: 10px;
    padding-bottom: 15px;
}
.contact-modal-form-field-label {
    margin-top: 8px;
    text-align: right;
    color: #121212;
    font-family: 'avaza';
    text-transform: capitalize;
}
.contact-modal-form-field {
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: 1px solid #e8e7e7;
    font-family: 'avaza';
}
.contact-modal-form-field-label.copy {
    margin-top: 0;
    text-align: left;
    cursor: pointer;
}
.contact-modal-form-field.copy {
    width: auto;
}
.contact-modal-form-adress-box {
    background-color: #e6e6e6;
    padding: 10px 15px;
}
.contact-modal-form-adress-name {
    color: #121212;
    font-family: 'avaza';
    text-transform: capitalize;
    font-size: 15px;
    margin-bottom: 5px;
}
.contact-modal-form-adress-email {
    color: #757575;
    text-transform: capitalize;
    font-size: 14px;
    margin: 0;
}
.contact-modal-form-wrap .subscribe-form-submit {
    margin-top: 0;
}
@media (max-width: 959px) {
    .feedback-btn-wrap {
        text-align: center;
    }
}
@media (max-width: 767px) {
    .page-sidebar {
        order: 2;
    }
    .article-wrap {
        padding: 10px;
    }
    .page-title {
        width: 100%;
    }
    a.page-title {
        width: auto;
    }
    .article-wrap img {
        float: none;
        margin: 0;
    }
    .page-headbar {
        display: block;
    }
    .page-headbar-btns {
        display: flex;
        justify-content: flex-start;
        margin-top: 12px;
        margin-left: 0;
    }
}
@media (max-width: 479px) {
    .contact-modal-form-field-label {
        text-align: left;
    }
}
/*------------------------------------*\
    PAGE END
\*------------------------------------*/

/*------------------------------------*\
    CONTACT
\*------------------------------------*/
.contact2-grid-wrap .uk-grid {
    margin-left: -20px;
}
.contact2-grid-wrap .uk-grid>* {
    padding-left: 20px;
}
.contact-info-block {
    background-color: #f0f0f0;
    margin-bottom: 25px;
}
.contact-info-block:last-of-type {
    margin-bottom: 0;
}
.contact-info-block-heading {
    background-color: #f0f0f0;
    padding: 25px;
    font-family: 'avaza_mtavruli';
    font-size: 20px;
    color: #121212;
}
.all-contacts-box .contact-info-block-heading {
    background-color: transparent;
    padding: 0;
    font-family: 'avaza';
    margin-bottom: 10px;
}
.contact-info-block-heading2 {
    margin: 0 0 18px 25px;
    font-family: 'avaza_mtavruli';
    font-size: 20px;
    color: #121212;
}
.contact-info-block-body {
    background-color: #fff;
    color: #121212;
    padding: 15px 25px;
}
.contact-info-block-body.style2 {
    min-height: 200px;
}
.contact2-grid-wrap {
    padding: 20px 25px;
}
.contact-info-block-body ul li {
    margin-bottom: 8px;
}
.contact-info-block-body ul li:last-of-type {
    margin-bottom: 0;
}
.contact-info-block-body ul a,
.contact-info-block-body ul b {
    color: #121212;
    font-family: 'avaza';
    font-size: 14px;
    line-height: 1.5;
    transition: 0.2s;
}
.contact-info-block-body ul a:hover {
    color: #c11c27;
}
.all-contacts-box {
    margin-top: 25px;
}
.all-contacts-box.sitemap ul li {
    margin-bottom: 0;
}
.all-contacts-box.sitemap ul li::before {
    content: "•";
    vertical-align: text-top;
}
.all-contacts-box.sitemap ul ul li {
    padding-left: 15px;
}
.all-contacts-box.sitemap ul ul li::before {
    color: grey;
}
.all-contacts-box.sitemap h3 {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    font-weight: bold;
}
.all-contacts-box hr {
    margin-top: 20px;
}
.all-contacts-box p {
    color: #121212;
    font-family: 'avaza';
    font-size: 14px;
    line-height: 1.5;
    transition: 0.2s;
}
.all-contacts-box a:hover {
    color: #c11c27;
}
@media (max-width: 767px) {
    .contact-info-block-heading2 {
        margin-left: 0;
    }
}
/*------------------------------------*\
    CONTACT END
\*------------------------------------*/

/*------------------------------------*\
    PAGES
\*------------------------------------*/
.pages-filter-box {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.pages-filter-inp-wrap {
    position: relative;
    display: inline-block;
}
.pages-filter-box div:nth-child(2), .pages-filter-box div:nth-child(3), .pages-filter-box div:nth-child(4) {
    margin-left: 15px;
}
.pages-filter-inp {
    max-width: 160px;
    padding: 15px 30px 14px 10px;
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.80);
    color: #fff;
    border: none;
    outline: none;
    font-family: 'avaza_mtavruli';
    font-size: 14px;
    transition: 0.2s;
}
.pages-filter-inp.btn {
    background-color: #c11c27;
    box-shadow: 0 0 0 0 #fff inset;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.pages-filter-inp[type=submit] {
    cursor: pointer;
}
.pages-filter-inp.no-padding-right {
    padding-right: 10px;
}
.pages-filter-box > div:nth-child(2) .pages-filter-inp,
/* staff selects */
.page-staff .pages-filter-box > div:nth-child(3) .pages-filter-inp {
    width: 200px !important;
    max-width: 100%;
}
.pages-filter-box div {
    margin: 2px !important;
    display: flex;
    align-items: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.32);
}
.pages-filter-total {
    text-align: right;
    padding-right: 3px;
    font-size: 30px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.32);
}
@media (max-width: 767px) {
    .pages-filter-total {
        text-align: left;
    }
}
/* staff selects end */
.pages-filter-inp::-webkit-input-placeholder {
  color: #fff;
}
.pages-filter-inp::-moz-placeholder {
  color: #fff;
}
.pages-filter-inp::-moz-input-placeholder {
  color: #fff;
}
.pages-filter-inp:-moz-placeholder {
  color: #fff;
}
.pages-filter-inp[readonly] {
    cursor: pointer;
}
.pages-filter-inp-icon {
    position: absolute;
    top: 14px;
    right: 10px;
}
.pages-filter-box .select2-container--default .select2-selection--single {
    border: none;
    outline: none;
    border-radius: 0;
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.80);
    font-family: 'avaza_mtavruli';
    font-size: 14px;
}
.pages-filter-box .select2-container .select2-selection--single {
    height: 45px;
}
.pages-filter-box .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px;
    color: #fff;
    padding-left: 10px;
}
.pages-filter-box .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 41px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #fff transparent transparent transparent;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #fff transparent;
}
.select2-dropdown {
    border: none;
    outline: none;
    border-radius: 0;
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.80);
}
.select2-results__option {
    color: #fff;
    padding-left: 10px;
    padding-top: 8px;
    font-family: 'avaza_mtavruli';
    font-size: 14px;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    font-family: 'avaza_mtavruli';
    color: #000;
}
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-results__option:hover {
    background: #fff;
    color: #000;
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 300px;
}
.white-back-wrap {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
}
.white-back-head-box {
    margin-bottom: 15px;
}
.white-back-head-title {
    color: #3d3d3d;
    font-size: 20px;
    font-family: 'avaza_mtavruli';
    margin-bottom: 10px;
}
.white-back-head-descr {
    color: #3d3d3d;
    font-size: 15px;
    font-family: 'avaza';
}
@media (max-width: 992px) {
    .pages-filter-box {
        flex-direction: column;
        align-items: flex-end;
    }
    .pages-filter-inp-wrap {
        margin-bottom: 10px;
    }
    .pages-filter-box div:nth-child(2), .pages-filter-box div:nth-child(3), .pages-filter-box div:nth-child(4) {
        margin-left: 0;
    }
}
@media (max-width: 767px) {
    .pages-filter-box {
        align-items: flex-start;
    }
    .pages-filter-inp {
        max-width: 200px;
        width: 200px;
    }
}
/*------------------------------------*\
    PAGES END
\*------------------------------------*/

/*------------------------------------*\
    PAGINATION
\*------------------------------------*/
.pagination-box {
    margin-top: 20px;
}
.pagination-box .uk-pagination>li>a {
    margin-top: 5px;
    padding: 10px;
    min-width: 20px;
    box-shadow: 0 0 0 3px #fff inset;
    background: rgba(30, 30, 30, 0.35);
    color: #fff;
    font-size: 14px;
    font-family: 'avaza_mtavruli';
    text-align: center;
    transition: 0.2s;
}
.pagination-box .uk-pagination>li>a:hover, .pagination-box .uk-pagination>li>a.active {
    background-color: #c11c27;
    box-shadow: 0 0 0 0 #fff inset;
}
/*------------------------------------*\
    PAGINATION END
\*------------------------------------*/

/*------------------------------------*\
    GALLERY
\*------------------------------------*/
.album-card {
    position: relative;
}
.album-card-img {
    width: 100%;
}
.album-card-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    color: #fff;
    background: linear-gradient(rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.4) 100%);
}
.page-headbar-btns .main-btn.page-share {
    margin-right: 0;
}
.gallery-img-hover {
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    position: absolute;
    background: rgba(30,30,30,0.5);
    z-index: 1;
    margin: 20px;
    top: 0;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    cursor: pointer;
}
.album-card:hover .gallery-img-hover {
    opacity: 1;
    visibility: visible;
}
.gallery-img-hover i {
    color: #ffb300;
    font-size: 35px;
}
@media (max-width: 767px) {
    .page-headbar-btns .main-btn.page-share {
        width: auto;
    }
}
/*------------------------------------*\
    GALLERY END
\*------------------------------------*/

/*------------------------------------*\
    TEXT PAGE
\*------------------------------------*/
.page-data {
    color: #545454;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
    word-wrap: break-word;
    font-family: "avaza";
}
#print-area p {
    font-family: "avaza";
}
.text-page-haeder {
    margin-bottom: 15px;
}
.text-page-title {
    color: #212121;
    font-size: 16px;
    font-family: "avaza_mtavruli";
    line-height: normal;
    font-weight: bold;
    margin-top: 15px;
    text-transform: uppercase;
}
.text-page-act-btn {
    padding: 10px;
    background-color: #f2f2f2;
    font-size: 16px;
    font-family: "avaza_mtavruli";
    color: #121212;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s;
    text-transform: uppercase;
}
.text-page-act-btn:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.text-page-act-btn i {
    font-size: 20px;
    margin-right: 5px;
    position: relative;
    top: 1px;
}
.page-data a {
    color: #b70004;
}
.page-data pre {
    margin: 0;
    white-space: pre-line;
    padding: 0;
    background: transparent; 
    font: inherit; 
    color: inherit; 
    -moz-tab-size: 4;
    tab-size: 4;
    overflow: auto;
}
@media (max-width: 767px) {
    .page-data {
        padding: 15px;
    }
    .text-page-act-btn {
        padding: 8px;
        font-size: 16px;
    }
    .middle-grid {
        order: -1;
    }
}
/*------------------------------------*\
    TEXT PAGE END
\*------------------------------------*/

/*------------------------------------*\
    PERSONAL CARD
\*------------------------------------*/
.personal-card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
    transition: 0.2s;
}
.personal-card.hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.16), 0 8px 16px rgba(0,0,0,0.16);
}
.personal-card-img-box {
    position: relative;
    overflow: hidden;
}
.personal-card-img {
    width: 100%;
    transition: all .3s ease-in;
}
.personal-card.hover .personal-card-img {
    transform: scale(1.04);
    transition: all .3s ease-out;
}
.personal-card-text-box {
    padding: 15px;
    background-color: #fff;
}
.personal-card-title {
    font-family: 'avaza_mtavruli';
    font-size: 16px;
    transition: 0.2s;
}
.personal-card.hover .personal-card-title {
    color: #b70004;
}
.personal-card-subtitle {
    font-family: 'avaza';
    font-size: 14px;
    color: #0c0c0c;
    margin: 5px 0;
}
.personal-card-email {
    color: #0c0c0c;
}
.personal-card-email:hover {
    color: #b70004;
}
/*------------------------------------*\
    PERSONAL CARD END
\*------------------------------------*/

/*------------------------------------*\
    NEWS PAGE
\*------------------------------------*/
.page-news > .uk-grid {
    margin-left: -25px;
    margin-bottom: -25px;
}
.page-news > .uk-grid>* {
    padding-left: 25px;
    padding-bottom: 25px;
}
.page-news .slider {
    margin: -25px -25px 15px;
}
.page-news .slider .uk-slideshow, .page-news .slider .uk-slideshow li {
    height: 400px;
}
.page-news .news-card-tags-wrap {
    margin-top: 0;
    padding-top: 4px;
}
.news-date {
    display: block;
    margin-bottom: 2px;
    color: #848484;
    font-family: "avaza_mtavruli";
}
.related-news {
    margin-bottom: 15px;
}
.announce-bottom {
    margin-bottom: 30px;
}
.announce-bottom .uk-grid {
    margin-left: -25px;
    margin-bottom: -25px;
}
.announce-bottom .uk-grid>* {
    padding-left: 25px;
    padding-bottom: 25px;
}
.slider-nav-wrap {
    max-width: 1200px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    left: 25px;
    bottom: 25px;
    pointer-events: none;
}
.slider-nav {
    background-color: #f3f3f3;
    display: flex;
    width: 88px;
    height: 66px;
    float: left;
    pointer-events: auto;
    box-shadow: 0 0px 3px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.21);
}
.slider-nav i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    color: #7e7e7e;
    cursor: pointer;
    font-size: 30px;
    transition: 0.2s;
}
.slider-nav i:hover {
    color: #3d3d3d;
}
@media (max-width: 767px) {
    .page-news > .uk-grid {
        margin-left: -10px;
        margin-bottom: -10px;
    }
    .page-news > .uk-grid>* {
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .page-news .slider {
        margin: -15px -15px 15px;
    }
    .text-page-act-btn-wrap {
        margin-bottom: -10px;
    }
    .text-page-act-btn {
        margin-bottom: 10px;
    }
    .page-news .slider .uk-slideshow, .page-news .slider .uk-slideshow li {
        height: 300px;
    }
    .announce-bottom .uk-grid {
        margin-left: -10px;
        margin-bottom: -10px;
    }
    .announce-bottom .uk-grid>* {
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .slider-nav-wrap {
        left: 15px;
        bottom: 15px;
    }
}
/*------------------------------------*\
    NEWS PAGE END
\*------------------------------------*/

/*------------------------------------*\
    ANNOUNCES PAGE
\*------------------------------------*/
.calendar {
    margin-bottom: 15px;
}
.announces-block {
    margin-bottom: 15px;
    background-color: #fff;
    padding: 15px 15px 11px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.16);
}
.announces-card {
    margin-bottom: 15px;
}
.announces-card:last-of-type {
    margin-bottom: 0;
}
.announces-card-title a {
    color: #3d3d3d;
    display: block;
    text-transform: uppercase;
}
.announces-card-date {
    margin-bottom: 5px;
    font-size: 13px;
    color: #848484;
    font-family: 'avaza_mtavruli';
}
.announcements .pages-header-wrap {
    padding-left: 15px;
}
.announces-scroll-list {
    height: 400px;
    resize: none;
}
.announcements .page-data .announces-card-date {
    font-weight: bold;
    font-family: 'avaza';
}
.announce-cards.uk-grid {
    margin-left: -20px;
    margin-bottom: 0;
}
.announce-cards.uk-grid>* {
    padding-left: 20px;
    padding-bottom: 0;
}
.announce-card {
    margin-bottom: 15px;
    padding: 20px 0;
    border-bottom: 1px solid #cdcdcd;
}
.announce-card-date {
    margin-bottom: 2px;
    font-size: 13px;
    font-family: 'avaza_mtavruli';
    color: #939598;
}
.announce-card-title {
    color: #212121;
    font-size: 16px;
}
.announce-cards > div:nth-last-of-type(-n+3) .announce-card {
    border-bottom: none;
}
.related-news .announces-card {
    background: #fff;
    margin-bottom: 15px;
    padding: 20px 15px 13px;
}
.related-news .announces-card-date {
    margin-bottom: 2px;
    font-size: 13px;
    font-family: 'avaza_mtavruli';
    color: #939598;
}
.related-news .announces-card-title {
    color: #212121;
    font-size: 16px;
}
.related-news .news-card-tags {
    margin-bottom: 0;
}
@media (max-width: 767px) {
    .announce-cards > div:nth-last-of-type(-n+3) .announce-card {
        border-bottom: 1px solid #cdcdcd;
    }
    .announce-cards > div:nth-last-of-type(-n+1) .announce-card {
        border-bottom: none;
    }
}
/*------------------------------------*\
    ANNOUNCES PAGE END
\*------------------------------------*/

/*------------------------------------*\
    ENROLL
\*------------------------------------*/
.enroll-wrap .background-block {
    align-items: center;
    padding: 20px 10px;
}
.enroll-title {
    font-size: 56px;
    font-family: 'avaza_mtavruli';
    color: #fff;
    text-align: center;
    margin-bottom: 5%;
}
.enroll-wrap .background-block-btns {
    margin: 0 auto;
    max-width: 820px;
}
.enroll-wrap .main-btn {
    font-size: 24px;
    min-width: 380px;
    margin: 10px;
}
.page-faculties .enroll-wrap .background-block-btns {
    max-width: 800px;
}
.page-faculties .enroll-wrap .main-btn {
    min-width: 370px;
}
@media (max-width: 767px) {
    .enroll-title {
        font-size: 22px
    }
    .enroll-wrap .main-btn,
    .page-faculties .enroll-wrap .main-btn {
        font-size: 14px;
        width: 290px;
        min-width: auto;
    }
}
/*------------------------------------*\
    ENROLL END
\*------------------------------------*/

/*------------------------------------*\
    FOOTER
\*------------------------------------*/
.footer {
    padding: 35px 50px 15px;
    position: relative;
    z-index: 2;
    background: #121212;
}
.footer-last .uk-grid {
    margin-left: -10px;
    margin-bottom: 0;
}
.footer-last .uk-grid>* {
    padding-left: 10px;
    padding-bottom: 0;
}
.copyright {
    text-align: center;
    font-family: 'avaza';
    color: #d6d6d6;
    font-size: 14px;
}
.footer-right-logo {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.topge {
    margin-right: 30px;
}
.footer-first {
    max-width: 1140px;
    margin: 0 auto 35px;
}
.footer-first .uk-grid {
    margin-left: -20px;
    position: relative;
    left: -70px;
}
.footer-first .uk-grid>* {
    padding-left: 20px;
}
.footer-first-text-small {
    margin: 15px 0 25px;
    color: #d6d6d6;
    font-size: 14px;
    line-height: 1.5;
}
.footer-list li {
    margin-bottom: 8px;
}
.footer-list a {
    font-family: 'avaza';
    color: #d6d6d6;
    font-size: 14px;
    line-height: 1.5;
    transition: 0.2s;
}
.footer-list a:hover {
    color: #fff;
}
.footer-list.fast-links a:before {
    content: ' ';
    display: block;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
}
.footer-list.social-links {
    text-align: right;
}
.footer-list.social-links li {
    margin-bottom: 33px;
}
.footer-list i {
    font-size: 27px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    width: 35px;
}
.footer-subscribe-wrap {
    margin: 40px 0 20px !important;
}
.footer-subscribe-title {
    font-size: 14px;
    color: #d6d6d6;
    font-family: 'avaza';
    max-width: 290px;
    line-height: 1.5;
    margin-bottom: 15px;
}
.footer-subscribe-box {
    position: relative;
    display: inline-block;
    border-radius: 2px;
    overflow: hidden;
}
.footer-subscribe-box i {
    position: absolute;
    left: 15px;
    font-size: 18px;
}
.footer-subscribe-field {
    width: 315px;
    height: 45px;
    border: none;
    outline: none;
    padding: 0 110px 0 50px;
    background-color: rgb(62, 62, 62);
    font-size: 14px;
    color: #fff;
    font-family: 'avaza';
}
.footer-subscribe-field::-webkit-input-placeholder {
    color: #d6d6d6;
}
.footer-subscribe-field::-moz-input-placeholder {
    color: #d6d6d6;
}
.footer-subscribe-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    font-family: 'avaza';
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    width: 100px;
    height: 45px;
    background-color: #c11c27;
}
@media (max-width: 991px) {
    .footer-subscribe-field {
        width: 270px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding: 15px 25px;
    }
    .footer-first .uk-grid {
        margin-left: 0;
        left: 0;
    }
    .footer-first .uk-grid>* {
        padding-left: 0;
    }
    .footer-list {
        text-align: center;
        margin: 20px 0 !important;
    }
    .footer-list.contact-info {
        margin-top: 40px !important;
    }
    .footer-list.social-links {
        text-align: center;
        margin-bottom: 10px !important;
    }
    .footer-list.social-links li {
        display: inline-block;
        margin: 5px 20px;
    }
    .footer-list.social-links li a {
        padding: 10px;
    }
    .footer-subscribe-wrap {
        text-align: center;
    }
    .footer-subscribe-title {
        margin: 0 auto 15px;
    }
    .footer-last > div:nth-of-type(1) {
        order: 2;
    }
    .footer-last > div:nth-of-type(2) {
        order: 3;
    }
    .footer-last > div:nth-of-type(3) {
        order: 2;
    }
    .copyright {
        margin-top: 20px;
    }
}
@media (max-width: 479px) {
    .footer-list.social-links li {
        margin: 5px 0;
    }
}


.current_menu_item {
    background-color: #fff;
}
/*------------------------------------*\
    FOOTER END
\*------------------------------------*/
