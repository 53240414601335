.noselect {
    -webkit-user-select: none;
    /* Chrome/Safari */
    
    -moz-user-select: none;
    /* Firefox */
    
    -ms-user-select: none;
    /* IE10+ */
}
#mini-clndr {
    font-family: 'avaza_mtavruli';
    margin: 0 auto;
    width: 100%;
    height: auto;
    /*  -moz-box-shadow: 4px 4px 0 #343434;
  -webkit-box-shadow: 4px 4px 0 #343434;
  box-shadow: 4px 4px 0 #343434;*/
}
#mini-clndr .clndr {
    /*overflow-x: hidden;*/
    
    overflow-y: visible;
    /*border-bottom: 7px solid #69a776;*/
}
#mini-clndr .clndr .controls {
    background-color: #414141;
    color: white;
}
#mini-clndr .clndr .controls .clndr-previous-button,
#mini-clndr .clndr .controls .clndr-next-button {
    width: 15%;
    padding-top: 3px;
    padding-bottom: 5px;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    /* Chrome/Safari */
    
    -moz-user-select: none;
    /* Firefox */
    
    -ms-user-select: none;
    /* IE10+ */
    
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s;
}
#mini-clndr .clndr .controls .clndr-previous-button:hover,
#mini-clndr .clndr .controls .clndr-next-button:hover {
    background-color: transparent;
}
#mini-clndr .clndr .controls .month {
    font-family: 'avaza_mtavruli';
    width: 70%;
    padding-top: 5px;
    padding-bottom: 0;
    display: inline-block;
    text-align: center;
}
#mini-clndr .clndr .days-container {
    position: relative;
    width: 100%;
    height: 317px;
    display: block;
    overflow: hidden;
}
#mini-clndr .clndr .days-container .days {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background-color: #ebebeb;
}
#mini-clndr .clndr .days-container .days .day,
#mini-clndr .clndr .days-container .days .empty {
    width: 14.28%;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1em;
    text-align: center;
    color: #212121;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
#mini-clndr .clndr .days-container .days .day.event,
#mini-clndr .clndr .days-container .days .empty.event {
    background-color: #fff;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s;
    cursor: pointer;
}
#mini-clndr .clndr .days-container .days .day.event:hover,
#mini-clndr .clndr .days-container .days .empty.event:hover {
    background-color: #b70004;
    color: #fff;
}
#mini-clndr .clndr .days-container .days .day.adjacent-month,
#mini-clndr .clndr .days-container .days .empty.adjacent-month {
    color: rgba(0, 0, 0, 0.3);
}
#mini-clndr .clndr .days-container .days .empty {
    height: 31px;
    vertical-align: bottom;
}
#mini-clndr .clndr .days-container .days .headers {
    background-color: #b70004;
    padding-top: 5px;
    padding-bottom: 5px;
}
#mini-clndr .clndr .days-container .days .headers .day-header {
    width: 14.28%;
    display: inline-block;
    text-align: center;
    color: white;
    padding-top: 4px;
    font-family: 'avaza_mtavruli';
}
#mini-clndr .calendar-day-row {
    padding-bottom: 3px;
    border-bottom: 2px solid #b70004;
}
#mini-clndr .day.adjacent-month.next-month .calendar-day-row {
    border-bottom: 2px solid #abc5cf;
}
#mini-clndr .announces-count {
    width: 25px;
    height: 25px;
    background: #b70004;
    border-radius: 50%;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    font-family: sans-serif;
    margin-left: 5px;
}
#mini-clndr .day.today {
    background-color: #fff;
}

#mini-clndr .clndr .days-container .events .events-list {
  overflow: scroll;
  height: 215px;
  overflow-x: hidden; 
}
#mini-clndr .clndr .days-container .events .events-list .event {
  padding-top: 14px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -ms-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
#mini-clndr .clndr .days-container .events .events-list .event:hover {
  background-color: #f5f5f5;
}
#mini-clndr .clndr .days-container .events .events-list .event a {
  position: relative;
  font-size: 12px;
  letter-spacing: 1px;
  background-color: transparent;
  color: #212121;
  text-decoration: none;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -ms-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}
#mini-clndr .clndr .days-container .events .events-list .event a:hover {
  background-color: transparent;
}
#mini-clndr .clndr .days-container.show-events .days {
    transform: translateX(-100%);
}
#mini-clndr .clndr .days-container .events {
	height: 100%;
    transform: translateX(100%);
    background: #fff;
    transition: 0.5s;
}
#mini-clndr .clndr .days-container.show-events .events {
  	transform: translateX(0);
}

#mini-clndr .clndr .days-container .events .headers {
  position: relative;
}
#mini-clndr .clndr .days-container .events .event-header {
  width: 100%;
  background-color: #b70004;
  padding-top: 11px;
  padding-bottom: 11px;
  text-align: center;
  color: white;
  font-size: 15px;
  font-family: 'avaza_mtavruli';
}
#mini-clndr .clndr .days-container .events .x-button {
  position: absolute;
  font-size: 14px;
  color: #fff;
  top: 11px;
  left: 20px;
  cursor: pointer;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -ms-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}


@media (max-width: 1200px) {
    #mini-clndr .clndr .controls .month {
        font-size: 14px;
    }
    #mini-clndr .announces-count {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}